.MuiTableRow-root .MuiTableCell-root {
  border: 0;
  padding: 0;
}

.MuiTable-root .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root {
  padding: 8px 12px !important;
}

.MuiTable-root .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root {
  padding: 10px 12px;
}

.MuiTable-root .MuiTableBody-root .MuiTableRow-root {
  height: 44px !important;
}
.MuiTable-root .MuiTableBody-root .MuiTableRow-root:last-child {
  height: 0 !important;
}

.MuiTable-root .MuiTableBody-root .MuiTableRow-root:nth-child(2n) {
  background-color: #fafafa;
}

tbody tr.MuiTableRow-root {
  transition: background-color 0.5s ease;
}

.MuiTable-root .MuiTableBody-root tr.MuiTableRow-root:nth-child(2n):hover,
tbody tr.MuiTableRow-root:hover,
tbody tr.MuiTableRow-root:not([index]):nth-child(even):hover {
  background-color: #f0f0f0;
}

.MuiTable-root tr.MuiTableRow-root.Mui-selected {
  background-color: #ffffff;
}

.MuiTable-root tr.MuiTableRow-root.Mui-selected:nth-child(2n) {
  background-color: #fafafa;
}

.MuiTable-root tr.MuiTableRow-root.Mui-selected:hover,
.MuiTable-root tr.MuiTableRow-root.Mui-selected:nth-child(2n):hover {
  background-color: #f0f0f0;
}

.MuiTableFooter-root {
  display: flex !important;
  justify-content: center;
  border: none;
  height: 60px;
  align-items: center;
  background-color: #fff;
}
.MuiTableFooter-root .MuiTableRow-footer {
  min-width: 250px;
}

.MuiButton-label {
  font-family: 'Poppins';
  font-weight: 400;
  color: inherit;
  margin-top: 1px;
  font-size: 14px;
  font-weight: inherit;
}

.MuiTablePagination-root .MTablePaginationInner-root-7 {
  margin: 0;
  width: 100%;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textSizeSmall.MuiButton-sizeSmall {
  color: #666666;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedSizeSmall.MuiButton-sizeSmall.Mui-disabled.Mui-disabled {
  border-radius: 30px;
  background-color: #002638;
  color: #ffffff;
  font-weight: 600 !important;
}

.MuiTablePagination-root .MuiTypography-caption {
  font-family: 'Poppins';
  font-weight: 600;
  color: inherit;
  margin-top: 1px;
  font-size: 14px;
  letter-spacing: 1px;
  min-width: 115px;
}
.MuiTablePagination-root button.Mui-disabled {
  opacity: 0.5;
}

/* Fix Logout */
.userMenu .MuiPaper-root ul.MuiMenu-list {
  display: flex;
}

.userMenu .MuiPaper-root ul.MuiMenu-list li.MuiButtonBase-root {
  padding: 6px 20px;
}
