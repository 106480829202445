[class$='MUIDataTable-responsiveBase']::-webkit-scrollbar {
  height: 10px;
  width: 7px;
}

[class$='MUIDataTable-responsiveBase']::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-right: none;
  border-left: none;
  border-radius: 8px;
  border: 3px solid #cccccc;
}

[class$='MUIDataTable-responsiveBase']::-webkit-scrollbar-track-piece {
  background: transparent;
  margin-top: 52px;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
  box-shadow: none;
  border: 1px solid #eeeeee;
  border-radius: 6px;
  font-family: 'Poppins';
  background-color: #ffffff;
  color: #888888;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded .MuiTableCell-root,
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded
  .MuiTableRow-root.Mui-selected:hover,
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded
  [class$='MUIDataTableHeadCell-contentWrapper'],
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded
  [class$='MUIDataTableHeadCell-sortAction'] {
  font-family: 'Poppins';
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded .MuiTableCell-root {
  background-color: transparent;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded .MuiTableCell-head,
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded
  .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular {
  background-color: #ffffff;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded
  .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular {
  border-radius: 6px 6px 0 0;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded .MuiButtonBase-root {
  padding: 0;
  margin: 0;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded
  .MuiTableRow-root.Mui-selected:hover {
  text-align: left;
  min-width: auto;
  font-family: 'Poppins';
  background-color: transparent;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded .MuiButtonBase-root:hover,
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded
  .MuiButtonBase-root:hover
  div {
  color: #444444;
  background-color: transparent;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded
  [class$='MUIDataTableHeadCell-contentWrapper'],
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded
  [class$='MUIDataTableHeadCell-sortAction'] {
  display: initial;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 0.75rem;
  color: #888888;
  letter-spacing: 0.1px;
  text-transform: uppercase;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded
  [class$='MUIDataTableHeadCell-sortAction'] {
  display: flex;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded .MuiTouchRipple-root {
  display: none;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded
  .MuiInputBase-root.MuiInput-root.MuiInput-underline:after,
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded
  .MuiInputBase-root.MuiInput-root.MuiInput-underline.Mui-focused:after,
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded
  .MuiInputBase-root.MuiInput-root.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-color: #0e374e !important;
}
