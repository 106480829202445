.react-multi-email > input {
  font: inherit;
  font-size: 16px;
  font-weight: 400;
  width: 100% !important;
  padding: 0.7em 0.1em !important;
  color: hsl(0, 0%, 20%);
  height: 15px;
}

.react-multi-email [data-tag] {
  max-width: none;
}

.react-multi-email.focused {
  border-color: #2684ff !important;
  box-shadow: 0 0 0 1px #2684ff !important;
}

.react-multi-email > input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
